import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';

export const COMPATIBLE_UMBRELLA_NAMES = {
  skyPostal: 'SkyPostal',
} satisfies Record<string, string>;

export const FormComponent = ({ onSuccess }: CourierFormComponentProps<FormValues>) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      <ControlledInput
        name="accountName"
        control={control}
        label="Account Name"
        statusText="The account name will be used to identify your account"
        placeholder="Create a nickname"
      />

      <ControlledInput name="userCode" control={control} label="User Code" />

      <ControlledInput name="userKey" control={control} label="User Key" />

      <ControlledInput name="appKey" control={control} label="App ID" />

      <ControlledInput name="copaId" control={control} label="Copa ID" />
    </Form>
  );
};
