import { z } from 'zod';

export const formSchema = z.object({
  accountName: z.string().min(1, 'Required field'),
  accountNumber: z.string().min(1, 'Required field'),
  companyName: z.string().min(1, 'Required field'),
  name: z.string().min(1, 'Required field'),
  phoneNumber: z.string().min(1, 'Required field'),
  email: z.string().min(1, 'Required field'),
  country: z.string().min(1, 'Required field'),
  postalCode: z.string().min(1, 'Required field'),
  city: z.string().min(1, 'Required field'),
  state: z.string().min(1, 'Required field'),
  addressLine1: z.string().min(1, 'Required field'),
  addressLine2: z.string().optional(),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
