import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';

export const COMPATIBLE_UMBRELLA_NAMES = {
  sendle: 'Sendle',
} satisfies Record<string, string>;

export const FormComponent = ({ onSuccess }: CourierFormComponentProps<FormValues>) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      <ControlledInput
        name="accountName"
        control={control}
        label="Account Name"
        placeholder="e.g. MyCourierAccount 01"
        statusText="The account name will be used to identify your account"
      />

      <ControlledInput name="id" control={control} label="Sendle ID" />

      <ControlledInput name="apiKey" control={control} label="Sendle API Key" />
    </Form>
  );
};
