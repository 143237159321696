import { Layout } from '@/components/Layout';
import { useCourierAccount } from '@/hooks/useCourierAccount';
import { routesList } from '@/router/routes-list';
import { useCallback, useMemo } from 'react';
import { Link, PathParam, generatePath, useNavigate, useParams } from 'react-router-dom';
import { supportUrl } from '@/helpers/supportUrl';
import { isSingleCountryCourier } from '@/helpers/courierFilters';
import { Pulse } from 'easyship-components';
import * as GenericForm from '@/forms/generic/GenericForm';
import * as ApgForm from '@/forms/apg/ApgForm';
import * as AuPostForm from '@/forms/auPost/AuPostForm';
import * as CanadaPostFrom from '@/forms/canadaPost/CanadaPostForm';
import * as DhlForm from '@/forms/dhl/DhlForm';
import * as FedexForm from '@/forms/fedex/FedexForm';
import * as PassportForm from '@/forms/passport/PassportForm';
import * as RoyalMailForm from '@/forms/royalMail/RoyalMailForm';
import * as SendleForm from '@/forms/sendle/SendleForm';
import * as SkypostalForm from '@/forms/skypostal/SkyPostalForm';
import * as UpsForm from '@/forms/ups/UpsForm';
import { useSubmitCourierMutation } from '@/hooks/useSubmitCourierMutation';
import { useCurrentUser } from '@/hooks/useCurrentUser';

const formComponentList = [
  GenericForm,
  ApgForm,
  AuPostForm,
  CanadaPostFrom,
  DhlForm,
  FedexForm,
  PassportForm,
  RoyalMailForm,
  SendleForm,
  SkypostalForm,
  UpsForm,
];

export const AddCourierPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const mutation = useSubmitCourierMutation();
  const { data: currentUser } = useCurrentUser();

  const params = useParams<Record<PathParam<typeof routesList.addCourier>, string>>();
  if (!params.id) throw new Error('Missing id param');

  const {
    data: courier,
    isLoading: courierLoading,
    error: courierError,
  } = useCourierAccount(params.id, currentUser?.companyId);

  const onSuccess = useCallback(
    (data: object) => {
      const companyId = currentUser?.companyId;

      if (!companyId || !courier) throw new Error('Unable to submit');

      // mutation.mutate({
      //   companyId,
      //   courier: CC.kebabCase(courier.courierAccount.umbrella_name),
      //   data,
      // });
    },
    [mutation, courier],
  );

  const FormComponent = useMemo<() => React.ReactNode>(() => {
    if (!courier) return () => null;

    const form = formComponentList.find((i) =>
      Object.values(i.COMPATIBLE_UMBRELLA_NAMES).includes(courier.umbrellaName),
    );

    if (!form) throw new Error(`Unable to render form for "${courier.umbrellaName}".`);

    return () => <form.FormComponent umbrellaName={courier.umbrellaName} onSuccess={onSuccess} />;
  }, [courier, onSuccess]);

  // Go to error page if request fails
  if (courierError) {
    navigate(routesList.error, {
      replace: false,
      state: courierError.message,
    });
  }

  if (courierLoading) return <Pulse />;

  return (
    <Layout title={`Connect ${courier?.umbrellaName}`}>
      {/* TODO: hide if _ this.showNewFedexLyoc && this.fedexCurrentStep !== FedexStep.Eula _ */}
      <p>
        Authenticate your <strong>{courier?.umbrellaName}</strong>{' '}
        {isSingleCountryCourier(courier?.umbrellaName || '') && <strong>{courier?.alpha2}</strong>}{' '}
        account to start shipping with your own negotiated rates. For detailed instructions, visit
        our{' '}
        <a
          className="text-blue-500"
          href={supportUrl(courier?.umbrellaName || '')}
          target="_blank"
          rel="noopener"
        >
          Support Page
        </a>
        .
      </p>
      <FormComponent />

      <Link to={generatePath(routesList.courierList)}>Back</Link>
    </Layout>
  );
};
