import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';

export const COMPATIBLE_UMBRELLA_NAMES = {
  royalMail: 'Royal Mail',
} satisfies Record<string, string>;

export const FormComponent = ({ onSuccess }: CourierFormComponentProps<FormValues>) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      <ControlledInput
        name="account_name"
        control={control}
        label="Account Name"
        placeholder="e.g. MyCourierAccount 01"
        statusText="The account name will be used to identify your account"
      />

      <ControlledInput
        name="account_number"
        control={control}
        label="Royal Mail Account Number"
        placeholder="e.g. 0407103001"
      />

      <ControlledInput
        name="nickname"
        control={control}
        label="Customer Name (as registered with Royal Mail)"
      />

      <ControlledInput
        name="oba_email_address"
        control={control}
        label="Royal Mail Online Business Account (OBA) Email Address"
      />

      <ControlledInput
        name="intersoft_posting_location"
        control={control}
        label="Royal Mail Posting Location (PLN)"
        placeholder="e.g. 900025716 (10 digits, starting with 9)"
      />
    </Form>
  );
};
