import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Courier } from '@/models/Courier';
import axios from 'axios';
import { useAuthContext } from '@/providers/AuthProvider';

export const useCourierAccount = (
  id: string,
  companyId: string | undefined,
): UseQueryResult<Courier> => {
  const { credentials } = useAuthContext();
  // TODO: understand why credentials are not being passed
  return useQuery<Courier>({
    enabled: !!(companyId && id),
    queryKey: ['courier-account', id],
    queryFn: async (): Promise<Courier> => {
      const { data } = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/api/v1/companies/${companyId}/courier_accounts/${id}`,
      );
      return {
        umbrellaName: data.umbrella_name,
        id: data.id,
        logoUrl: data.logo_url,
        alpha2: data.origin_country_alpha2,
      };
    },
    staleTime: Infinity,
  });
};
