import { Card } from 'easyship-components';

interface CourierCardProps {
  logo?: string | null;
  name: string;
  onClick?: () => void;
}

export const CourierCard = ({ logo, name, onClick }: CourierCardProps) => {
  return (
    <Card
      variant="normal"
      className="flex items-center px-5 py-3 min-h-[70px] cursor-pointer shadow hover:shadow-md border-none transition-shadow gap-2"
      onClick={onClick}
    >
      <img
        src={`${import.meta.env.VITE_APP_STORAGE}/courier-logos/${logo || 'nonbranded'}-mini.svg`}
        alt={`${name} logo`}
        className="w-[54px]"
      />
      <p className="text-ink-500">{name}</p>
    </Card>
  );
};
