import { z } from 'zod';

export const formSchema = z.object({
  nickname: z.string().nullable(),
  companyName: z.string().min(1, 'Required field'),
  name: z.string().min(1, 'Required field'),
  contact: z.string().min(1, 'Required field'),
  email: z.string().email(),
  country: z.string().min(1, 'Required field'),
  address1: z.string().min(1, 'Required field'),
  address2: z.string().nullable(),
  zipCode: z.string().min(1, 'Required field'),
  city: z.string().min(1, 'Required field'),
  state: z.string().min(1, 'Required field'),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
