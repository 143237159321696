import { z } from 'zod';

export const formSchema = z.object({
  account_number: z.string().min(1, 'Required field'),
  nickname: z.string().min(1, 'Required field'),
  account_name: z.string().min(1, 'Required field'),
  oba_email_address: z.string().min(1, 'Required field'),
  intersoft_posting_location: z
    .string()
    .length(10, 'Field should contain 10 digits')
    .startsWith('9', 'Invalid format'),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
