import React from 'react';
import { Button } from 'easyship-components';

/**
 * This component is responsible for the generic UI styles of the courier forms
 */

export const Form = ({
  children,
  className,
  ...props
}: React.FormHTMLAttributes<HTMLFormElement>): React.ReactElement => (
  <form className={`flex gap-y-3 flex-wrap ${className ?? ''}`} {...props}>
    {children}
    <Button type="submit" color="primary" className="my-5">
      Submit
    </Button>
  </form>
);
