import { Layout } from '@/components/Layout';
import * as AddressForm from '@/forms/address';
import { useBillingAddress } from '@/hooks/useBillingAddress';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useEffect } from 'react';

export const AddressPage = (): React.ReactElement => {
  const { data: currentUser } = useCurrentUser();
  const billingAddress = useBillingAddress(currentUser?.companyId);

  useEffect(() => {
    console.log('currentUser', currentUser);
  }, [currentUser]);

  useEffect(() => {
    console.log('billingAddress', billingAddress);
  }, [billingAddress]);

  return (
    <Layout title="Where are you shipping from?">
      <AddressForm.FormComponent
        onSuccess={(data) => {
          console.log('success', data);
        }}
      />
    </Layout>
  );
};
