import { Courier } from '@/models/Courier';

// Show the same form for different AU Post related couriers
export const isAustraliaPostCourier = (logoUrl: string) => {
  const australiaPostRelatedCouriers = ['australia-post', 'australia-post-on-demand', 'star-track'];

  return australiaPostRelatedCouriers.includes(logoUrl);
};

export const isSingleCountryCourier = (logoUrl: string) => {
  const nonInterationalCouriers = [
    'australia-post',
    'australia-post-on-demand',
    'star-track',
    'sendle',
    'australia-post-mypost-business',
    'canada-post',
    'royal-mail',
    'passport',
  ];

  return nonInterationalCouriers.includes(logoUrl);
};

export const updateCourierData = (availableCouriers: string[], courierList: Courier[]) => {
  return availableCouriers.map((courier) => {
    const courierData = courierList.find((c) => c.logoUrl === courier);
    if (!courierData) return;
    return {
      umbrellaName: courierData.umbrellaName,
      logoUrl: courierData.logoUrl,
      id: courierData.id,
      alpha2: courierData.alpha2,
    };
  });
};
