import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Address } from '@/models/Address';
import axios from 'axios';

export const useBillingAddress = (companyId: string | undefined): UseQueryResult<Address> => {
  return useQuery<Address>({
    enabled: !!companyId,
    queryKey: ['all-couriers', companyId],
    queryFn: async (): Promise<Address> => {
      const { data } = await axios.get(
        `${import.meta.env.VITE_API_PUBLIC_BASE_URL}/api/v1/companies/${companyId}/addresses`,
      );
      return data?.addresses?.find(
        (i: Address) =>
          'default_values' in i &&
          typeof i.default_values === 'object' &&
          i.default_values !== null &&
          'billing' in i.default_values &&
          i.default_values.billing,
      );
    },
    staleTime: Infinity,
  });
};
