import { Pulse, Select, Skeleton } from 'easyship-components';
import { useEffect, useMemo, useState } from 'react';
import { CourierCard } from '@/components/CourierCard';
import { useCountries } from '@/hooks/useCountries';
import { Layout } from '@/components/Layout';
import { generatePath, useNavigate } from 'react-router-dom';
import { routesList } from '@/router/routes-list';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { Country } from '@/models/Address';
import { Courier } from '@/models/Courier';
import { useCourierAccounts } from '@/hooks/useCourierAccounts';

export const CourierListPage = () => {
  const navigate = useNavigate();
  const { data: countries, isLoading: countriesLoading, error: countriesError } = useCountries();
  const { data: currentUser, isLoading: currentUserLoading } = useCurrentUser();
  const {
    data: courierAccounts,
    isLoading: courierAccountsLoading,
    error: courierAccountsError,
  } = useCourierAccounts(currentUser?.companyId);

  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(undefined);

  const handleCountryChange = (alpha2: string) => {
    setSelectedCountry(countries?.find((country) => country?.alpha2 === alpha2));
  };

  const availableCountries = useMemo(
    () =>
      countries?.filter((country) =>
        currentUser?.shippingCountries.some((sc) => sc['alpha2' as 'name'] === country.alpha2),
      ),
    [countries, currentUser],
  );

  const availableCouriers = useMemo(() => {
    const couriers = currentUser?.shippingCountries.find(
      (sc) => sc.alpha2 === selectedCountry?.alpha2,
    )?.availableCouriers;

    if (!couriers || !courierAccounts) return [];

    return couriers.map((courier) => {
      const courierData = courierAccounts.find((c) => c.logoUrl === courier);
      return {
        umbrellaName: courierData?.umbrellaName || '',
        logoUrl: courierData?.logoUrl || '',
        id: courierData?.id || '',
        alpha2: courierData?.alpha2 || '',
      };
    });
  }, [courierAccounts, currentUser, selectedCountry]);

  const handleCourierCardClick = (id: string) => {
    navigate(
      generatePath(routesList.addCourier, {
        id,
      }),
    );
  };

  // Set default country if there are available countries
  useEffect(() => {
    if (availableCountries && availableCountries.length > 0) {
      setSelectedCountry(availableCountries[0]);
    }
  }, [availableCountries]);

  if (courierAccountsError || countriesError) {
    navigate(routesList.error, {
      replace: true,
      state: courierAccountsError?.message ?? countriesError?.message,
    });
  }

  return (
    <Layout title="Connect your courier">
      <p>Select the country your courier account is registered in.</p>

      {countriesLoading ? (
        <Pulse show={true} />
      ) : (
        <>
          {availableCountries ? (
            <Select
              label="Country"
              onChange={(_, option) => {
                if (option) handleCountryChange(option.value);
              }}
              value={
                selectedCountry
                  ? {
                      value: selectedCountry.alpha2,
                      label: selectedCountry.name,
                    }
                  : undefined
              }
              options={availableCountries.map((country) => ({
                value: country.alpha2,
                label: country.name,
              }))}
            />
          ) : (
            <p>There are no available countries.</p>
          )}
        </>
      )}

      {selectedCountry && <p>Select the courier you want to connect with your Easyship account.</p>}

      {(countriesLoading || currentUserLoading || courierAccountsLoading) &&
        Object.keys(Array.from(Array(5))).map((i) => (
          <Skeleton key={i} height={100} width="100%" rounded />
        ))}

      {availableCouriers.map((courier: Courier) => (
        <CourierCard
          key={courier.id}
          name={courier.umbrellaName}
          logo={courier.logoUrl}
          onClick={() => handleCourierCardClick(courier.id)}
        />
      ))}

      {selectedCountry && courierAccounts?.length === 0 && (
        <p>
          Sorry, we currently don't support courier integrations for shipments origination from{' '}
          {selectedCountry.name}
        </p>
      )}
    </Layout>
  );
};
