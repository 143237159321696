import React from 'react';
import { routesList } from '@/router/routes-list';
import { useNavigate } from 'react-router-dom';
import { Button } from 'easyship-components';
import { LayoutError } from '@/components/LayoutError';

export const NotFoundPAge = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <LayoutError title="Page not found">
      <Button
        className="mt-6"
        color="primary"
        onClick={() => {
          navigate(routesList.home, {
            replace: true,
          });
        }}
      >
        Retry
      </Button>
    </LayoutError>
  );
};
