import { Exclaim } from 'easyship-components/icons';
import React from 'react';

export interface LayoutErrorProps {
  title: string;
  children: React.ReactNode;
}

export const LayoutError = ({ title, children }: LayoutErrorProps): React.ReactElement => (
  <div className="flex flex-col items-center justify-center h-screen bg-sky-300 text-ink-700">
    <div className="text-[72px]">
      <Exclaim className="w-24 h-24 mb-6 text-sky-700" />
    </div>
    <h4 className="m-2 text-2xl font-bold">{title}</h4>
    {children}
  </div>
);
