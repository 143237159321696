import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Country } from '@/models/Address';
import axios from 'axios';

export const useCountries = (): UseQueryResult<Country[]> => {
  return useQuery<Country[]>({
    queryKey: ['countries'],
    queryFn: async (): Promise<Country[]> => {
      const { data } = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/countries`);
      return (
        data?.countries
          ?.map((country: Country) => ({
            name: country.name || '',
            alpha2: country.alpha2 || '',
          }))
          .sort((a: Country, b: Country) => (String(a.name) > String(b.name) ? 1 : -1)) || []
      );
    },
    staleTime: Infinity,
  });
};
