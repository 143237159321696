import React from 'react';
import { ErrorPage } from '@/pages/ErrorPage';
import { ErrorBoundaryProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

export const ErrorBoundary: React.FC<Pick<ErrorBoundaryProps, 'children'>> = ({ children }) => (
  <ReactErrorBoundary FallbackComponent={ErrorPage}>
    {/* When the error boundary is used as a react router template */}
    <Outlet />

    {/* When the error boundary is used in regular scenarios like app entry point */}
    {children}
  </ReactErrorBoundary>
);
