import {
    Control,
    Controller,
    ControllerProps,
    FieldPath,
    FieldValues,
    RegisterOptions,
  } from 'react-hook-form';
  import { Input, InputProps } from 'easyship-components';

  export type ControlledInputProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  > = Pick<ControllerProps<TFieldValues, TName>, 'name' | 'control'> &
    Pick<InputProps, 'label' | 'placeholder' | 'statusText' | 'type'>;

    export const ControlledInput = <
      TFieldValues extends FieldValues = FieldValues,
      TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    >({
  name,
    control,
    label,
    placeholder,
    statusText,
    type,
  }: ControlledInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, value, onBlur, disabled, name }, fieldState }) => (
        <Input
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          value={value}
          inputContainerRef={ref}
          label={label}
          type={type}
          status={!!fieldState.error ? 'error' : 'default'}
          statusText={
            fieldState.error?.message || <p className="text-ink-100 italic">{statusText}</p>
          }
          placeholder={placeholder}
        />
      )}
    />
  );
};
