import { FormValues, formSchema } from './schema';
import { FormComponentProps } from '../types';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';
import { useEffect } from 'react';
import { useCountries } from '@/hooks/useCountries';
import { Select } from 'easyship-components';

export const FormComponent = ({ onSuccess }: FormComponentProps<FormValues>) => {
  const countries = useCountries();

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    console.log('countries', countries);
  }, [countries]);

  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      <ControlledInput name="nickname" control={control} label="Nickname (optional)" />

      <ControlledInput name="companyName" control={control} label="Company Name" />
      <ControlledInput name="name" control={control} label="Name" />
      <ControlledInput name="contact" control={control} label="Contact No." />
      <ControlledInput name="email" control={control} label="Email" />

      <Controller
        name="country"
        control={control}
        render={({ field: { ref, onChange, value }, fieldState }) => (
          <Select
            label="Country"
            ref={ref}
            options={countries.data?.map((i) => ({
              value: i.alpha2,
              label: String(i.name),
            }))}
            onChange={onChange}
            status={!!fieldState.error ? 'error' : 'default'}
            statusText={fieldState.error?.message}
            value={countries.data
              ?.map((i) => ({
                value: i.alpha2,
                label: String(i.name),
              }))
              .find((i) => i.value === value)}
          />
        )}
      />

      <ControlledInput name="address1" control={control} label="Address Line 1" />
      <ControlledInput name="address2" control={control} label="Address Line 2 (optional)" />

      <ControlledInput name="zipCode" control={control} label="Zip Code" />
      <ControlledInput name="city" control={control} label="City" />
      <ControlledInput name="state" control={control} label="State" />
    </Form>
  );
};
