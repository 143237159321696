import { z } from 'zod';

export const formSchema = z.object({
  accountName: z.string().min(1, 'Required field'),
  username: z.string().min(1, 'Required field'),
  password: z.string().min(1, 'Required field'),
  originCode: z.string().min(1, 'Required field'),
  senderCode: z.string().min(1, 'Required field'),
});

export type FormValues = z.infer<typeof formSchema>;
