import { DefaultError, UseMutationResult, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Courier } from '@/models/Courier';

/*
      POST https://api-qa4.easyship.com/api/v1/companies/735e5ca7-f615-429e-b02a-1857fd25adba/courier_accounts/dhl
      account: {
          "nickname": "DHL Test",
          "account_number": "aaaaa",
          "api_key": "aaaaaaaaaaaaaa",
          "api_secret": "aaaaaaaaaaaaaaaa",
          "origin_country_id": 78
      }
    */

interface SubmitCourierAccountData {
  total_couriers_count?: number;
  couriers?: Courier[];
}

export type UseSubmitCourierData = SubmitCourierAccountData | undefined;

export type UseSubmitCourierMutationResult = UseMutationResult<
  UseSubmitCourierData,
  DefaultError,
  {
    companyId: string;
    courier: string;
    data: object;
  }
>;

export const useSubmitCourierMutation = (): UseSubmitCourierMutationResult => {
  return useMutation<
    UseSubmitCourierData,
    DefaultError,
    {
      companyId: string;
      courier: string;
      data: object;
    }
  >({
    mutationFn: async ({ companyId, courier, data }) =>
      await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/api/v1/companies/${companyId}/courier_accounts/${courier}`,
        data,
      ),
  });
};
