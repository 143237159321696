export const supportUrl = (courier: string) => {
  let url = import.meta.env.VITE_APP_HELP;
  switch (courier) {
    case 'fedex': {
      url += '/hc/en-us/articles/360056947771-Link-Your-FedEx-Account';
      break;
    }
    case 'dhl': {
      url += '/hc/en-us/articles/360039958872-Link-Your-DHL-Express-Account';
      break;
    }
    case 'australia-post': {
      url += '/hc/en-us/articles/360027090711-Link-Your-Account-Australia-Post';
      break;
    }
    case 'ups': {
      url += '/hc/en-us/articles/360039959232-Link-Your-UPS-Account';
      break;
    }
    case 'startrack': {
      url += '/hc/en-us/';
      break;
    }
    case 'australia-post-on-demand': {
      url += '/hc/en-us/articles/360027090711-Link-Your-Account-Australia-Post';
      break;
    }
    case 'royal-mail': {
      url += '/hc/en-us/articles/360046578311-Link-Your-Royal-Mail-Account';
      break;
    }
    case 'sendle': {
      url += '/hc/en-us/articles/360035365152/';
      break;
    }
    case 'canada-post': {
      url += '/hc/en-us/articles/4407016235533-Link-Your-Canada-Post-Account';
      break;
    }
    case 'au-post': {
      url += '/hc/en-us/articles/12379903974809-Link-Your-Australia-Post-Mypost-Business-Account';
      break;
      // } case 'passport': {
      //   url += '/hc/en-us/articles/23885130521625-Link-Your-Passport-Account';
      //   break;
    }
    default: {
      url += '/hc/en-us';
      break;
    }
  }

  return url;
};
