import React from 'react';
import { routesList } from '@/router/routes-list';
import { FallbackProps } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'easyship-components';
import { LayoutError } from '@/components/LayoutError';

// TODO: display user friendly errors
// TODO: Get design for error page

/**
 * This component is used as an error display page,
 * It is used normally on the router also as an error boundary fallback,
 * thats the reason for using the error boundary props
 */

export const ErrorPage = ({ error }: Partial<FallbackProps>): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <LayoutError title="We encountered an error.">
      <p>Please retry or contact support for assistance if the issue persists.</p>

      <Button
        className="mt-6"
        color="primary"
        onClick={() => {
          navigate(routesList.home, {
            replace: true,
          });
        }}
      >
        Retry
      </Button>
    </LayoutError>
  );
};
