import { z } from 'zod';

export const formSchema = z.object({
  accountName: z.string().min(1, 'Required field'),
  accountNumber: z.string().length(9, 'Field should contain 9 digits'),
  apiKey: z.string().length(14, 'Field should contain 14 digits'),
  apiSecret: z.string().length(16, 'Field should contain 16 digits'),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
