import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';

export function toastError(error?: string, toastOptions?: ToastOptions) {
  if (error) {
    toast.error(error, { ...toastOptions });
  } else {
    toast.error('Something went wrong. Please try again.', { ...toastOptions });
  }
}

export function toastSuccess(message: string, toastOptions?: ToastOptions) {
  toast.success(message, { ...toastOptions });
}

export function toastInfo(message: string, toastOptions?: ToastOptions) {
  toast.info(message, { ...toastOptions });
}

export function Toastify() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      draggable={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="light"
    />
  );
}
