import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from 'easyship-components';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';

const ORIGIN_CODES = [
  { label: 'JFK', value: 'jfk' },
  { label: 'LAX', value: 'lax' },
  { label: 'MIA', value: 'mia' },
  { label: 'ORD', value: 'ord' },
];

export const COMPATIBLE_UMBRELLA_NAMES = {
  apg: 'APG',
} satisfies Record<string, string>;

export const FormComponent = ({ onSuccess }: CourierFormComponentProps<FormValues>) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      <ControlledInput
        name="accountName"
        control={control}
        label="Account Name"
        placeholder="e.g. MyCourierAccount 01"
        statusText="The account name will be used to identify your account"
      />

      <ControlledInput name="username" control={control} label="Username" />

      <ControlledInput name="password" control={control} label="Password" type="password" />

      <Controller
        name="originCode"
        control={control}
        render={({ field: { ref, onChange, value }, fieldState }) => (
          <Select
            label="Origin Code"
            ref={ref}
            options={ORIGIN_CODES}
            onChange={onChange}
            status={!!fieldState.error ? 'error' : 'default'}
            statusText={fieldState.error?.message}
            value={ORIGIN_CODES.find((option) => option.value === value)}
          />
        )}
      />

      <ControlledInput name="senderCode" control={control} label="Sender Code" />
    </Form>
  );
};
