import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useToken = (tempToken: string): UseQueryResult<string> => {
  return useQuery<string>({
    enabled: !!tempToken,
    queryKey: ['token', tempToken],
    queryFn: async (): Promise<string> => {
      const { data } = await axios.post(
        `${import.meta.env.VITE_API_AUTH_BASE_URL}/apps/access_tokens`,
        { token: tempToken },
      );
      return data?.access_token?.token || '';
    },
    staleTime: Infinity,
  });
};
