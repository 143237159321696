import React from 'react';

export interface LayoutProps {
  title: string;
  children: React.ReactNode;
}

export const Layout = ({ title, children }: LayoutProps): React.ReactElement => (
  // TODO: remove width and padding when development is done
  <div className="text-ink-900 text-base w-[400px] p-[50px] box-content mx-auto flex flex-col gap-3">
    <h1 className="text-xl">{title}</h1>
    {children}

    <img src="/powered_by.svg" />
  </div>
);
