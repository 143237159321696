import { Button } from 'easyship-components';
import { COMPATIBLE_UMBRELLA_NAMES } from './FedexForm';

interface PdfAgreementProps {
  umbrellaName: string;
  acceptAgreement: (isAccepted: boolean) => void;
}

export const PdfAgreement = ({ umbrellaName, acceptAgreement }: PdfAgreementProps) => {
  // TODO: implement UserSession
  // const isNewFedexLyocFeatureEnabled = UserSession.isNewFedexLyocEnabled();
  const isNewFedexLyocFeatureEnabled = false;

  const getFedexEulaPdf = () => {
    let fileName = 'fedex-eula-distribution-agreement';

    if (umbrellaName === COMPATIBLE_UMBRELLA_NAMES.fedexCrossBorder) {
      fileName = 'fedex-cross-border-eula';
    } else if (isNewFedexLyocFeatureEnabled) {
      fileName = 'fedex-eula-v2';
    }

    return `${import.meta.env.VITE_APP_STORAGE}/courier-docs/${fileName}.pdf`;
  };

  return (
    <>
      <p>In order to continue, you will need to agree to the FedEx EULA.</p>
      <iframe className="h-[380px] w-full" src={`${getFedexEulaPdf()}#zoom=FitW`}></iframe>
      <p className="text-xs mt-3 text-ink-100">
        FedEx service marks are owned by Federal Express Corporation and are used by permission.
      </p>
      <div className="flex justify-end">
        <Button onClick={() => acceptAgreement(true)} color="primary">
          I accept
        </Button>
      </div>
    </>
  );
};
