import { z } from 'zod';

export const formSchema = z.object({
  accountName: z.string().min(1, 'Required field'),
  accountNumber: z.string().min(1, 'Required field'),
  password: z.string().min(1, 'Required field'),
  userId: z.string().min(1, 'Required field'),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
