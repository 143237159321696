import { useToken } from '@/hooks/useToken';
import { createContext, useContext } from 'react';
import Cookies from 'js-cookie';

interface AuthContextProps {
  credentials: string | undefined;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextProps>({ credentials: undefined });

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const { data: authToken } = useToken(token || '');

  if (authToken) {
    const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;
    Cookies.set(credentialsEnv, authToken);
  }
  return <AuthContext.Provider value={{ credentials: authToken }}>{children}</AuthContext.Provider>;
};
